<template>
  <div>
    <div class="title">考勤报表</div>

    <el-divider></el-divider>

    <!-- table -->
    <div class="table_title">汇总统计表</div>
    <el-table
      :data="summaryTableData"
      :header-cell-style="tableHeaderStyle"
      style="width: 100%;"
    >
      <el-table-column prop="name" label="报表名称"> </el-table-column>
      <el-table-column prop="describe" label="描述"> </el-table-column>
      <el-table-column label="操作">
        <div class="operations">
          <div>导出</div>
        </div>
      </el-table-column>
    </el-table>

    <div class="table_title">明细记录表</div>
    <el-table
      :data="DetailedTableData"
      :header-cell-style="tableHeaderStyle"
      style="width: 100%;"
    >
      <el-table-column prop="name" label="报表名称"> </el-table-column>
      <el-table-column prop="describe" label="描述"> </el-table-column>
      <el-table-column label="操作">
        <div class="operations">
          <div>导出</div>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      summaryTableData: [
        {
          name: "考勤汇总表（样式一）",
          describe: "员工当月出勤、请假、外勤、加班等所有考勤情况的汇总统计",
        },
        {
          name: "异常考勤汇总表",
          describe: "员工当月迟到、早退、旷工、缺卡等情况的汇总统计",
        },
        {
          name: "补卡统计表",
          describe: "员工当月补卡次数统计及补卡明细展示",
        },
      ],
      DetailedTableData: [
        {
          name: "打卡时间表",
          describe: "员工当月每天打卡时间的明细展示",
        },
        {
          name: "异常考勤明细表",
          describe: "员工当月迟到、早退、旷工、缺卡等情况的明细展示",
        },
        {
          name: "打卡时间表",
          describe: "员工当月每天打卡时间的明细展示",
        },
      ],

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.el-table{
  margin-bottom: 60px;
}

.operations {
  display: flex;
  align-items: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}
</style>
